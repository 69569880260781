import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';

export const BulletCheckDrawn = ({ size = 22, color = 'black', settings = false }) => {
  const { t } = useTranslation();
  const pathWidth = 20;
  const pathHeight = 22;
  const viewBoxWidth = 39;
  const viewBoxHeight = 39;
  const ratio = () => size / pathHeight;

  return (
    <svg
      aria-label={t('app.image.role-icon')}
      className="thumbnail-icon tiny"
      width={`${size}px`}
      height={`${size}px`}
      color={color}
    >
      <path
        className="cls-b6"
        style={{
          transform: `scale(${ratio()}, ${ratio()}) translate(${settings ? (viewBoxWidth - pathWidth) / 2 : 0}px, ${
            settings ? (viewBoxHeight - pathHeight) / 2 : (pathHeight - pathWidth) / 2
          }px)`,
        }}
        fill={color}
        d="M0 12L7.01575 20C10.5904 12.8402 14.9664 6.25347 22 0C16.7359 2.91867 10.9332 8.46105 5.97638 15.3191L0 12Z"
      />
    </svg>
  );
};
