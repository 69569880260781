import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { Status, TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/types';
import { useStyles } from '@wix/tpa-settings/react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import React, { FC } from 'react';

import { DataHook } from '../../../types/data-hook';
import { SimpleReward, SimpleRule, SimpleTier } from '../../../types/domain';
import { TabState } from '../../../types/settings';
import { EmptyState } from './EmptyState';
import { ErrorState } from './ErrorState';
import { useNumberFormatter } from './hooks';
import { MainProgram } from './MainProgram';
import { TiersProgram } from './TiersProgram';

export type ControllerProps = {
  hasError: boolean;
  tabState: TabState;
  loyaltyProgram: LoyaltyProgram;
  isLoggedIn: boolean;
  hasMyRewardsPage: boolean;
  onNavigateToMyRewardsPage(): void;
  simpleRules: SimpleRule[];
  simpleRewards: SimpleReward[];
  tiersProgramSettings: TiersProgramSettings;
  simpleTiers: SimpleTier[];
  isProgramAvailable: boolean;
  locale: string;
};

const Widget: FC<WidgetProps<ControllerProps>> = ({
  hasError,
  tabState,
  loyaltyProgram,
  isLoggedIn,
  onNavigateToMyRewardsPage,
  hasMyRewardsPage,
  simpleRules,
  simpleRewards,
  tiersProgramSettings,
  simpleTiers,
  isProgramAvailable,
  locale,
}) => {
  const styles = useStyles();
  const { formatNumber } = useNumberFormatter(locale);

  if (hasError) {
    return <ErrorState />;
  }

  if (!isProgramAvailable) {
    return <EmptyState />;
  }

  const showTiers =
    styles.getStylesForAllBreakpoints().some((breakpointStyles) => breakpointStyles.booleans.showTiers) &&
    tiersProgramSettings?.status === Status.ACTIVE;

  return (
    <div data-hook={DataHook.LoyaltyWidget}>
      <MainProgram
        {...{
          tabState,
          loyaltyProgram,
          isLoggedIn,
          onNavigateToMyRewardsPage,
          hasMyRewardsPage,
          simpleRules,
          simpleRewards,
          showTiers,
        }}
      />
      {showTiers && (
        <TiersProgram {...{ simpleTiers, simpleRules, simpleRewards, tiersProgramSettings, formatNumber }} />
      )}
    </div>
  );
};

export default Widget;
